<template>
  <div class="grid grid-cols-1 xs:grid-cols-3 xs:grid-rows-2 gap-3 sm:gap-4 lg:gap-x-8 my-6 px-4">
    <!--GUEST BUTTONS FOR 1-6 GUESTS-->
    <!--If max_guests is greater than 6, will display 1-5 buttons and the guest dropdown will be the 6th one-->
    <button
      v-for="count in allowsMoreThan6Guests ? 5 : GUEST_BUTTONS_IN_GRID"
      :key="count"
      :id="guestGuestButtonId(count)"
      :class="BUTTON_CLASSES"
      class="guest-button-outsider"
      @click="setGuestCount(count)"
      :disabled="!canGuestCountBeSet(count)"
    >
      {{ guestCountLabel(count) }}
    </button>

    <!--GUEST DROPDOWN FOR 6+ GUESTS-->
    <OnClickOutside
      v-if="allowsMoreThan6Guests"
      @trigger="isGuestCountMenuOpen = false"
      id="guest-dropdown"
    >
      <button
        id="guest-trigger-button"
        :disabled="isButtonGroupDisabled"
        :class="BUTTON_CLASSES"
        class="h-full"
        @click="isGuestCountMenuOpen = !isGuestCountMenuOpen"
        ref="reference"
      >
        <span class="mx-auto inline-block">{{ GUEST_BUTTONS_IN_GRID }} - {{ max_guests }}</span>
        <ChevronDownIcon class="w-4 h-4" />
      </button>

      <div
        ref="floating"
        id="guests-menu"
        v-show="isGuestCountMenuOpen"
        :style="floatingStyles"
        class="flex flex-col py-1 max-h-[45vh] min-w-[56px] overflow-y-auto tt-guest-scroll z-10 shadow-lg bg-body-primary rounded border border-linear-strong"
      >
        <button
          v-for="count in rangeFromMaxGuests"
          :key="count"
          :id="guestGuestButtonId(count)"
          class="px-4 py-1 text-fg-g-primary hover:bg-button-guest-primary hover:text-button-label disabled:disabled disabled:bg-disabled disabled:text-button-label disabled:cursor-not-allowed"
          @click="setGuestCount(count)"
          :disabled="!canGuestCountBeSet(count)"
        >
          {{ guestCountLabel(count) }}
        </button>
      </div>
    </OnClickOutside>
  </div>
</template>

<script setup lang="ts">
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'
import { OnClickOutside } from '@vueuse/components'
import * as _ from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import ChevronDownIcon from '@/assets/chevronDown.svg?component'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

import { FeatureFlag, PerformanceTableRead } from '@generated/types'

interface GuestRange {
  min: number
  max: number
}

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: 'click:guest', guestCount: number): void
}>()

const GUEST_BUTTONS_IN_GRID = 6
const BUTTON_CLASSES =
  'flex justify-center items-center px-4 py-2 text-sm md:text-base rounded font-button font-semibold w-full bg-button-guest-primary text-button-label transition-opacity hover:opacity-90 disabled:disabled disabled:bg-disabled disabled:text-fg-inverse disabled:cursor-not-allowed'

const isGuestCountMenuOpen = ref(false)
const reference = ref(null)
const floating = ref(null)
const { floatingStyles } = useFloating(reference, floating, {
  placement: 'bottom-end',
  middleware: [offset(10), flip({ fallbackAxisSideDirection: 'start' }), shift()],
  whileElementsMounted: autoUpdate,
})

const guestGuestButtonId = (count: number): string => `${count}-guests-button`

const { max_guests } = storeToRefs(useSettingStore())
const allowsMoreThan6Guests = computed<boolean>(() => max_guests.value > GUEST_BUTTONS_IN_GRID)
const rangeFromMaxGuests = computed<number[]>(() =>
  _.range(GUEST_BUTTONS_IN_GRID, max_guests.value + 1),
)
const isButtonGroupDisabled = computed<boolean>(() =>
  rangeFromMaxGuests.value.every(i => !canGuestCountBeSet(i)),
)

const checkoutStore = useCheckoutStore()
const availableTables = computed<PerformanceTableRead[]>(() =>
  (checkoutStore.performance?.tables || []).filter(table => table.available),
)
const achievableGuestCounts = computed<boolean[]>(() => {
  const guestRangeFromRegularTables: GuestRange[] = availableTables.value
    .filter(table => !table.standing_room)
    .map(table => ({
      min: table.minimum_guests,
      max: table.available_guests,
    }))

  const canAchieve = new Array(max_guests.value).fill(false)
  canAchieve[0] = true

  for (let range of guestRangeFromRegularTables) {
    for (let i = max_guests.value - 1; i >= 0; i--) {
      if (canAchieve[i]) {
        for (let value = range.min; value <= Math.min(range.max, max_guests.value - i); value++) {
          canAchieve[i + value] = true
        }
      }
    }
  }

  return canAchieve
})

function guestCountLabel(count: number): string {
  if (!canGuestCountBeSet(count)) {
    return `${count} (SOLD OUT)`
  } else {
    return `${count}`
  }
}

const settingStore = useSettingStore()
const enforceMinimums = computed<boolean>(() =>
  settingStore.flagEnabled(FeatureFlag.ENFORCE_MINIMUMS),
)
function canGuestCountBeSet(guestCount: number): boolean {
  // First, check if any standing room table can accommodate all guests
  const suitableStandingRoom = availableTables.value.find(
    table =>
      table.standing_room &&
      table.available_guests >= guestCount &&
      (!enforceMinimums.value || table.minimum_guests <= guestCount),
  )
  if (suitableStandingRoom) return true

  // Next, check if any regular table can accommodate all guests
  const availableRegularTables = availableTables.value.filter(table => !table.standing_room)
  if (availableRegularTables.length === 0) return false // No suitable arrangement found
  const lowestMinimum = Math.min(...availableRegularTables.map(table => table.minimum_guests))
  if (lowestMinimum > guestCount) return false // No suitable arrangement found

  if (!enforceMinimums.value) {
    const availableSeats = _.sum(availableRegularTables.map(table => table.available_guests))
    return availableSeats >= guestCount
  } else {
    // Finally, check if the target sum can be achieved by any combination of regular tables
    return achievableGuestCounts.value[guestCount]
  }
}
function setGuestCount(count: number): void {
  if (canGuestCountBeSet(count)) {
    emit('click:guest', count)
  }
}
</script>
