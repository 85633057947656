<template>
  <RoomMap id="set-tables-room-table" :backgroundMode="roomMapBgMode">
    <RoomTable
      v-for="table in disabledTables"
      :key="table.id"
      :id="`table-${table.id}`"
      :height="table.h"
      :width="table.w"
      :rotation="table.rotation"
      :shape="table.shape"
      :x="table.x"
      :y="table.y"
      marker="xmark"
      class="cursor-not-allowed unavailable"
      fillClass="fill-table-disabled"
    />
    <RoomTable
      v-for="table in tablesNotFulfillTableMinimum"
      :key="table.id"
      :id="`table-${table.id}`"
      @click="selectTable(table.id)"
      :height="table.h"
      :width="table.w"
      :rotation="table.rotation"
      :shape="table.shape"
      :x="table.x"
      :y="table.y"
      class="cursor-pointer notFitMinimum"
      fill-class="fill-table-notFitMinimum"
    />
    <RoomTable
      v-for="table in availableTables"
      :key="table.id"
      :id="`table-${table.id}`"
      @click="selectTable(table.id)"
      :height="table.h"
      :width="table.w"
      :rotation="table.rotation"
      :shape="table.shape"
      :x="table.x"
      :y="table.y"
      :marker="
        table.isSelected && selectedTables.length === 1 && reservedGuests === number_of_guests
          ? 'checkmark'
          : 'none'
      "
      class="cursor-pointer"
      :class="table.isSelected ? 'active' : 'available'"
      :fillClass="table.isSelected ? 'fill-primary' : 'fill-table-available'"
      textFillClass="fill-button-label"
      strokeClass="!outline-none focus:stroke-body-primary"
      @keydown.enter.space="selectTable(table.id)"
      :ariaLabel="getAriaLabel(table, table.isSelected)"
      :isFocused="focusTableId === `table-${table.id}`"
      focusable
    >
      {{ table.isSelected ? seatedTables.map(t => t.id).indexOf(table.id) + 1 : '' }}
    </RoomTable>
  </RoomMap>
</template>

<script lang="ts">
import { useActiveElement } from '@vueuse/core'
import { orderBy } from 'lodash-es'
import { mapState, mapStores } from 'pinia'
import { computed, defineComponent } from 'vue'

import RoomTable from '@/common/components/molecules/RoomTable.vue'
import RoomMap from '@/common/components/organisms/RoomMap.vue'
import { ROOM_MAP_BG_MODE, RoomMapBgMode } from '@/common/interfaces/RoomMap'
import { EnrichedTable, tablesForSeatedParty } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { byId, sum } from '@/utils/utils'

import { FeatureFlag, PerformanceTableId, PerformanceTableRead } from '@generated/types'

export default defineComponent({
  name: 'SetTablesRoomMap',
  components: { RoomTable, RoomMap },
  setup() {
    const activeElement = useActiveElement()
    const focusTableId = computed<string | undefined>(() => {
      if (!activeElement.value) return
      const group = activeElement.value.closest('g')
      if (!group) return
      return group.id
    })
    return { focusTableId }
  },
  async mounted() {
    if (!this.checkoutStore.performance?.id) {
      return
    }
    await this.checkoutStore.refreshPerformance()
  },
  data() {
    return {
      roomMapBgMode:
        getComputedStyle(document.documentElement).getPropertyValue('--mode') ||
        (ROOM_MAP_BG_MODE.FORCE_LIGHT as RoomMapBgMode),
    }
  },
  methods: {
    async selectTable(tableId: number) {
      const selectedTable = this.tables.find(byId(tableId)) as PerformanceTableRead

      const indexOfSelected = this.selectedTableIds.indexOf(selectedTable.id)
      if (indexOfSelected > -1) {
        // on click, deselect any already selected table
        this.selectedTableIds.splice(indexOfSelected, 1)
        return
      }

      if (!selectedTable.available || selectedTable.minimum_guests > this.number_of_guests) {
        // bail out if the table is not available or the party is too small
        return
      }

      if (selectedTable.maximum_guests >= this.number_of_guests) {
        // quick bail if the table holds the entire party
        this.selectedTableIds = [selectedTable.id]
        return
      }

      while (this.selectedTableIds.length > 0) {
        // assume we completely fill the just-selected table, seat remaining guests at previously selected tables,
        let seated = tablesForSeatedParty(
          this.performance,
          this.selectedTableIds,
          this.number_of_guests - selectedTable.maximum_guests,
        )
        // remove the earliest selected table if any table is totally empty, then start over
        const shouldStartOver = this.enforceTableMinimumEnabled
          ? seated.some(table => table.guests < table.minimum_guests)
          : seated.some(table => table.guests === 0)
        if (shouldStartOver) {
          this.selectedTableIds.shift()
        } else {
          // now try the whole party with all the tables
          seated = tablesForSeatedParty(
            this.performance,
            [...this.selectedTableIds, selectedTable.id],
            this.number_of_guests,
          )
          if (seated.some(table => table.guests == 0)) {
            this.selectedTableIds.shift()
          } else {
            break
          }
        }
      }

      // we delay actually selecting the table until the very end in order to simplify running the algorithm above
      this.selectedTableIds.push(selectedTable.id)
    },
    getAriaLabel(table: PerformanceTableRead, isSelected: boolean): string {
      const selected = isSelected ? 'Selected' : 'Not Selected'
      return `${selected}, ${
        this.settingStore.tableType(table.type_id).name
      }, ${this.settingStore.currency(table.price_per_person)} per person`
    },
  },
  computed: {
    ...mapStores(useCheckoutStore, useSettingStore),
    ...mapState(useCheckoutStore, ['performance', 'number_of_guests']),
    selectedTableIds: {
      get(): PerformanceTableId[] {
        return this.checkoutStore.tables
      },
      set(value: PerformanceTableId[]) {
        this.checkoutStore.tables = value
      },
    },
    enforceTableMinimumEnabled(): boolean {
      return this.settingStore.flagEnabled(FeatureFlag.ENFORCE_MINIMUMS)
    },
    tables(): PerformanceTableRead[] {
      return (this.performance && this.performance.tables) || []
    },
    tablesNotFulfillTableMinimum(): PerformanceTableRead[] {
      if (
        this.enforceTableMinimumEnabled &&
        this.reservedGuests > 0 &&
        this.reservedGuests < this.number_of_guests
      ) {
        return this.tables.filter(
          t =>
            !this.disabledTables.map(t => t.id).includes(t.id) &&
            !this.selectedTableIds.includes(t.id) &&
            t.minimum_guests > this.number_of_guests - this.reservedGuests,
        )
      } else if (this.enforceTableMinimumEnabled && this.reservedGuests === this.number_of_guests) {
        return this.tables.filter(
          t =>
            !this.disabledTables.map(t => t.id).includes(t.id) &&
            !this.selectedTableIds.includes(t.id),
        )
      } else {
        return []
      }
    },
    availableTables(): Array<PerformanceTableRead & { isSelected: boolean }> {
      const tables = this.tables
        .filter(
          table =>
            !this.disabledTables.includes(table) &&
            !this.tablesNotFulfillTableMinimum.includes(table),
        )
        .map(table => {
          let canSeatAllGuestsOrder: number
          if (
            table.minimum_guests === this.number_of_guests &&
            table.maximum_guests === this.number_of_guests
          ) {
            canSeatAllGuestsOrder = 1
          } else if (
            table.minimum_guests < this.number_of_guests &&
            table.maximum_guests === this.number_of_guests
          ) {
            canSeatAllGuestsOrder = 2
          } else if (
            table.minimum_guests < this.number_of_guests &&
            table.maximum_guests > this.number_of_guests
          ) {
            canSeatAllGuestsOrder = 3
          } else {
            canSeatAllGuestsOrder = 4
          }
          return {
            ...table,
            isSelected: this.selectedTableIds.includes(table.id),
            order: canSeatAllGuestsOrder,
            price_per_person: +table.price_per_person,
          }
        })
      return orderBy(tables, ['order', 'price_per_person'], ['asc', 'asc'])
    },
    selectedTables(): PerformanceTableRead[] {
      return this.tables.filter(table => this.selectedTableIds.includes(table.id))
    },
    disabledTables(): PerformanceTableRead[] {
      let standingRoomFilter = (t: PerformanceTableRead) => !t
      if (this.selectedTables.some(t => !t.standing_room)) {
        // if a user has selected a seated table, disable all standing room tables
        standingRoomFilter = t => t.standing_room
      } else if (this.selectedTables.some(t => t.standing_room)) {
        // if a user has selected a standing room table, disable all seated tables
        standingRoomFilter = t => !t.standing_room
      }
      return this.tables.filter(
        table =>
          !table.available ||
          standingRoomFilter(table) ||
          this.number_of_guests < table.minimum_guests || // party too small for the table
          (table.standing_room && this.number_of_guests > table.available_guests), // standing_rooms need to accommodate the entire party
      )
    },
    reservedGuests(): number {
      const guests = sum(this.seatedTables.map(table => table.guests))
      return Math.min(guests, this.number_of_guests)
    },
    seatedTables(): EnrichedTable[] {
      return tablesForSeatedParty(this.performance, this.selectedTableIds, this.number_of_guests)
    },
  },
})
</script>
