import { until, useDark } from '@vueuse/core'
import { Moment } from 'moment'
import moment from 'moment-timezone'
import { defineStore } from 'pinia'
import { toRefs } from 'vue'

import * as api from '@/api/api'
import { byId } from '@/utils/utils'

import { FeatureFlagType, GuestTagId, SettingRead, TableTypeRead } from '@generated/types'

export interface Settings extends SettingRead {
  loaded: boolean
  initialized: boolean

  assets_domain: string
  currentVenueAppVersion: string | null
  newVenueAppVersion: string | null
  dark_background_path: string

  ssrTitle: string | null
  ssrDescription: string | null
  ssrImage: string | null
  siteDomain: string | null
}

interface TaxRateStringParam {
  tax: any
  amount: any
  fee?: any
  service_fee?: any
}

export const useSettingStore = defineStore({
  id: 'setting',
  state: (): Settings => {
    if (typeof window === 'object' && window?.__tt_preload?.setting) {
      console.log('loading from preload')
      return {
        ...window.__tt_preload.setting,
        loaded: true,
        initialized: true,
      }
    } else {
      return {
        loaded: false,
        initialized: false,
        assets_domain: 'assets-prod.turntabletickets.com',
        background_path: '',
        dark_background_path: '',
        checkout_text: '',
        checkout_confirmation_text: '',
        company_id: -1,
        css_url: '',
        link_url: 'https://turntabletickets.com',
        logo_url: '',
        max_guests: 6,
        public_key: '',
        ratio: 1.5,
        reply_to: '',
        seating_time_text: '',
        service_fee: -1,
        service_percentage: 0,
        terminal_service_fee: -1,
        terminal_service_percentage: 0,
        stripe_account: '',
        stripe_charges_enabled: true,
        tax_rate: -1,
        fee_tax_rate: -1,
        table_types: [],
        timezone: 'US/Central',
        venue_id: -1,
        venue_name: '',
        venue_slug: '',
        venue_currency: 'USD',
        venue_locale: 'en_US',
        features: {
          promo_codes: false,
          standing_room: false,
          enforce_minimums: false,
          fee_disclosure: false,
          terminal: false,
          skip_seating_time: false,
        },
        currentVenueAppVersion: null,
        newVenueAppVersion: null,
        guest_tags: [],
        reservation_tags: [],
        ssrTitle: null,
        ssrDescription: null,
        ssrImage: null,
        siteDomain: null,
        application_fee: 0,
        application_fee_per_reservation: 0,
      }
    }
  },
  getters: {
    loading(): Promise<boolean> {
      //@ts-ignore
      this.fetch() // TODO: this is a hack to make sure we load the settings
      const { loaded } = toRefs(this)
      return until(loaded).toBe(true)
    },
    preload(): string {
      return JSON.stringify(this.$state)
    },
    currencyFormat(): Intl.NumberFormat {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.venue_currency,
      })
    },
    currencyFormatStripDec(): Intl.NumberFormat {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.venue_currency,
        minimumFractionDigits: 0,
      })
    },
    locale(): string {
      return this.venue_locale.replace('_', '-')
    },
    applyAssetsDomain() {
      return (url_path: string): string => {
        if (url_path.startsWith('http')) {
          return url_path
        }
        if (!url_path.startsWith('/')) {
          url_path = '/' + url_path
        }
        if (this.assets_domain) {
          return `//${this.assets_domain}${url_path}`
        } else {
          return url_path
        }
      }
    },
    flagEnabled() {
      return (flag: FeatureFlagType): boolean => flag in this.features && this.features[flag]
    },
    parseMoment() {
      return (datetime: string): Moment => moment.tz(datetime, this.timezone)
    },
    formatTemporal() {
      return (datetime: string, format: string) => this.parseMoment(datetime).format(format)
    },
    dateIsoStr() {
      return (datetime: string) => this.formatTemporal(datetime, 'YYYY-MM-DD')
    },
    time24Str() {
      return (datetime: string) => this.formatTemporal(datetime, 'HH:mm')
    },
    fromNowStr() {
      return (datetime: string) => this.parseMoment(datetime).fromNow()
    },
    nowDateTime() {
      return () => this.parseMoment(moment().toISOString()).utc().format()
    },
    hasDateTimePast() {
      return (datetime: string) => this.parseMoment(datetime).isBefore(this.nowDateTime())
    },
    currency() {
      return (value: number | string) => this.currencyFormat.format(Number(value))
    },
    currencyRoundUp() {
      return (value: number | string) =>
        this.currencyFormatStripDec.format(Math.ceil(Number(value)))
    },
    makeDateTime() {
      return ({ time, date = '2022-01-01', time_format = 'HH:mm' }) => {
        return moment
          .tz(`${date} ${time}`, `YYYY-MM-DD ${time_format}`, true, this.timezone)
          .utc()
          .format()
      }
    },
    tableType() {
      return (id: number | undefined): TableTypeRead => {
        if (!id || !this.table_types.find(byId(id))) {
          return {
            id: 0,
            name: 'Table',
            abbreviation: '',
          }
        }
        return this.table_types.find(byId(id)) as TableTypeRead
      }
    },
    taxRateString() {
      return ({ tax, amount, fee = 0, service_fee = 0 }: TaxRateStringParam) => {
        // This is a whole lot of nonsense for the rare case that a venue's tax rate has changed,
        // We check to see if the tax amount is wildly different, if it is we return the calculated
        // tax rate, otherwise we return the configured one (because floating point math sucks)
        const taxed = Number(amount) + Number(fee || service_fee || 0)
        if (Math.abs((this.tax_rate * taxed) / 100 - Number(tax)) < 0.02) {
          return this.tax_rate.toFixed(2)
        } else {
          return ((Number(tax) / taxed) * 100).toFixed(2)
        }
      }
    },
    hasVenueAppVersionChanged(): boolean {
      if (this.currentVenueAppVersion && this.newVenueAppVersion) {
        return this.currentVenueAppVersion !== this.newVenueAppVersion
      }
      return false
    },
    fee_disclosure_enabled(): boolean {
      return this.features.fee_disclosure
    },
    backgroundImage(): string {
      const dark = useDark()
      if (dark.value) {
        return this.dark_background_path
      }
      return this.background_path
    },
    domain(): string {
      return this.siteDomain || window.location.origin
    },
  },
  actions: {
    async fetch(force: boolean = false) {
      if (!this.initialized || force) {
        this.initialized = true
        const response = await api.getSettings()
        this.setSetting(response.data)
      }
    },
    setSetting(setting: SettingRead) {
      this.$patch({
        ...setting,
        fee_tax_rate: +setting.fee_tax_rate,
        initialized: true,
        loaded: true,
      })
    },
    updateVenueAppVersion(venueAppVersion: string) {
      if (this.currentVenueAppVersion === null) {
        this.currentVenueAppVersion = venueAppVersion
      } else {
        this.newVenueAppVersion = venueAppVersion
      }
    },
    getGuestTag(tagId: GuestTagId) {
      return this.guest_tags.find(byId(tagId))
    },
    getReservationTag(tagId: number) {
      return this.reservation_tags.find(byId(tagId))
    },
    weekdayLong(datetime: string) {
      return this.formatTemporal(datetime, 'dddd')
    },
    shortDate(datetime: string) {
      return this.formatTemporal(datetime, 'MM/DD/YYYY')
    },
    mediumDate(datetime: string) {
      return this.formatTemporal(datetime, 'ddd, MMM DD')
    },
    longDate(datetime: string) {
      return this.formatTemporal(datetime, 'MMM DD, YYYY')
    },
    fullDate(datetime: string) {
      return this.formatTemporal(datetime, 'ddd, MMM DD, YYYY')
    },
    timeStr(datetime: string) {
      return this.formatTemporal(datetime, 'hh:mm A')
    },
    fromNow(datetime: string, withoutSuffix: boolean = false): string {
      return this.parseMoment(datetime).fromNow(withoutSuffix)
    },
    timeBetween(from: string, to: string, withoutSuffix: boolean = false): string {
      return this.parseMoment(from).to(this.parseMoment(to), withoutSuffix)
    },
  },
})
